import { Icon, IconOutlined } from '~common/icons'

export enum ActionType {
  BULK = 'bulk',
  RECORD = 'record',
}

export type DataTableActionItem = {
  id: string
  label: string
  icon?: Icon | IconOutlined
  type: ActionType
  permission?: () => boolean
  condition?: () => boolean
}

type ActionFn = (permFunc?: () => boolean, condFunc?: () => boolean) => DataTableActionItem

export const acceptBulkAction: ActionFn = (permFunc, condFunc) => {
  const obj: DataTableActionItem = {
    id: 'ACCEPT_BULK',
    label: 'common.actions.AcceptSelected',
    icon: IconOutlined.Check,
    type: ActionType.BULK,
  }
  if (permFunc) {
    obj.permission = permFunc
  }
  if (condFunc) {
    obj.condition = condFunc
  }
  return obj
}

export const rejectBulkAction: ActionFn = (permFunc, condFunc) => {
  const obj: DataTableActionItem = {
    id: 'REJECT_BULK',
    label: 'common.actions.RejectSelected',
    icon: IconOutlined.Check,
    type: ActionType.BULK,
  }
  if (permFunc) {
    obj.permission = permFunc
  }
  if (condFunc) {
    obj.condition = condFunc
  }
  return obj
}

export const editRecordAction: ActionFn = (permFunc, condFunc) => {
  const obj: DataTableActionItem = {
    id: 'EDIT_RECORD',
    label: 'common.actions.Edit',
    icon: IconOutlined.Edit,
    type: ActionType.RECORD,
  }
  if (permFunc) {
    obj.permission = permFunc
  }
  if (condFunc) {
    obj.condition = condFunc
  }
  return obj
}
